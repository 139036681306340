<template>
  <watetReharge :priceList="priceList" :types="types"></watetReharge>
</template>
<script>
import watetReharge from '../water-recharge/Index.vue'
export default {
  data () {
    return {
      types: [2,3,4,5,6,7],
      priceList: [
        {
          type: 2,
          price: '',
          number: '',
          money: '',
          title: '红砖',
          remark: '',
        },
        {
          type: 3,
          price: '',
          number: '',
          money: '',
          title: '轻质砖',
          remark: '',
        },
        {
          type: 4,
          price: '',
          number: '',
          money: '',
          title: '水泥',
          remark: '',
        },
        {
          type: 5,
          price: '',
          number: '',
          money: '',
          title: '沙',
          remark: '',
        },
        {
          type: 6,
          price: '',
          number: '',
          money: '',
          title: '外墙瓷砖',
          remark: '',
        },
        {
          type: 7,
          price: '',
          number: '',
          money: '',
          title: '其他装修材料',
          remark: '',
        },
      ]
    }
  },
  components: {
    watetReharge
  }
}
</script>